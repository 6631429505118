import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TraineeGender = _resolveComponent("TraineeGender")!
  const _component_InfoContainer = _resolveComponent("InfoContainer")!
  const _component_TraineeAffiliation = _resolveComponent("TraineeAffiliation")!
  const _component_LocalCapacity = _resolveComponent("LocalCapacity")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_InfoContainer, { title: "Trainee Gender" }, {
      graph: _withCtx(() => [
        _createVNode(_component_TraineeGender)
      ]),
      _: 1
    }),
    _createVNode(_component_InfoContainer, { title: "Trainee Affiliation" }, {
      graph: _withCtx(() => [
        _createVNode(_component_TraineeAffiliation)
      ]),
      _: 1
    }),
    _createVNode(_component_InfoContainer, { title: "Local Capacity" }, {
      graph: _withCtx(() => [
        _createVNode(_component_LocalCapacity)
      ]),
      _: 1
    })
  ], 64))
}