
import { defineComponent } from 'vue';
import InfoContainer from '@/components/InfoContainer/index.vue';
import TraineeGender from './TraineeGender.vue';
import TraineeAffiliation from './TraineeAffiliation.vue';
import LocalCapacity from './LocalCapacity.vue';

export default defineComponent({
  name: 'PieCharts',
  components: {
    InfoContainer,
    TraineeGender,
    TraineeAffiliation,
    LocalCapacity,
  },
});
