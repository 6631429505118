import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "t-gender",
  ref: "TraineeGenderRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!

  return (_ctx.dataExists)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
    : (_openBlock(), _createBlock(_component_Empty, {
        key: 1,
        description: "No data to visualize"
      }))
}