
import { defineComponent } from 'vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { mapGetters } from 'vuex';
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'TraineeGender',
  data() {
    return {
      dataExists: true,
    };
  },
  components: {
    Empty,
  },
  mounted() {
    this.pie();
  },
  computed: {
    ...mapGetters('training', ['getLoadingState']),
  },
  methods: {
    renderTraineeGender():Data[] {
      const data = [0, 0, 0];
      const values = this.$store.getters['training/getFilterValues'];
      if (values && values.length > 0) {
        const index = values[0].indexOf('Gender of Trainee');
        values.forEach((v:string[], i:number) => {
          if (i !== 0 && index <= v.length - 1) {
            if ((/M/ig).test(v[index])) {
              data[0] += 1;
            } else if ((/F/ig).test(v[index])) {
              data[1] += 1;
            } else if ((/other/ig).test(v[index])) {
              data[2] += 1;
            }
          }
        });
      }
      const traces:Data = {
        values: data,
        labels: ['Males', 'Females', 'Other'],
        marker: {
          colors: ['#534193', '#7e69ca', '#b29dff'],
        },
        hole: 0.7,
        type: 'pie',
        textinfo: 'none',
        hovertemplate: ' <b style="color: %{color}; fontSize: 20px;">&#10687;</b>  <span><b>%{label}</b>  %{percent}, n=%{value}</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      return [traces];
    },
    pie() {
      const t = this.renderTraineeGender();
      // @ts-expect-error values not found in Data plotly issue
      this.dataExists = t[0].values.some((x) => (x !== 0)) && this.getLoadingState === 'done';
      this.$nextTick(() => {
        if (this.dataExists) {
          const { width, height } = (this.$refs.TraineeGenderRef as Element).getBoundingClientRect();
          const layout = {
            width,
            height,
            legend: {
              xanchor: 'center',
              yanchor: 'top',
              font: {
                family: 'DINNextLTPro-Medium, Arial',
                size: 12,
              },
              y: 0,
              x: 0.5,
              orientation: 'h',
            },
            margin: {
              t: 0,
              l: 65,
              r: 65,
            },
          };
          // @ts-expect-error enum error expected
          Plotly.react(this.$refs.TraineeGenderRef, t, layout, { responsive: true });
        }
      });
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        this.pie();
      }
    },
  },
});
